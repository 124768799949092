import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css" // requires a loader
import ContactButton from "../components/contactButton"

import first from "../images/1.png"
import second from "../images/2.png"
import third from "../images/3.png"

export default function Produtos() {
  return (
    <Layout titlePage="Produtos" titleMargin="45" subtitle>
      <Seo
        title="Produtos"
        keywords={[`Inovação`, `Usinagem`, `Ferramentaria`]}
      />
      <div className="grid md:grid-cols-2 md:gap-12 my-32">
        <Carousel infiniteLoop showIndicators={false} showStatus={false}>
          <img src={first} />
          <img src={second} />
          <img src={third} />
        </Carousel>
        <div className="text-center mr-10">
          <h1>Válvula Simples</h1>
          <h3 className="my-10">Temos em três dimensões:</h3>
          <div className="card mb-20">
            <h2>800 / 900</h2>
          </div>
          <h3 className="mb-10">Ficou interessado em saber mais?</h3>
          <ContactButton />
        </div>
      </div>
    </Layout>
  )
}
